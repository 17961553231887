var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('router-link',{attrs:{"to":{ name: 'PracticesList' }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fa-angle-left")]),_c('span',[_vm._v("Все практики")])],1)],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('item-edit-view',{attrs:{"title":"Практика","subtitle":_vm.item ? _vm.item.name ? _vm.item.name.ru : ''  : null,"loading":_vm.loading},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',[_c('v-btn',{attrs:{"right":"","color":"success","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Сохранить ")],1)],1)]},proxy:true}],null,true)},[(!_vm.loading && _vm.item)?_c('v-card',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"page"},[_vm._v("Страница")]),_c('v-tab',{key:"contacts"},[_vm._v("Контакты")]),_c('v-tab',{key:"management"},[_vm._v("Руководители")]),_c('v-tab',{key:"other"},[_vm._v("Разное")])],1),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"page"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_c('v-checkbox',{attrs:{"label":"Активно"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]),_c('h2',{staticClass:"mb-4"},[_vm._v("Название практики")]),_c('locale-field',{staticClass:"mb-12",attrs:{"rules":"required","component":"v-text-field","name":"name","placeholder":"Введите название практики","dense":"","hide-details":"","outlined":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('slug-editor',{staticClass:"mt-8 mb-8",attrs:{"name":"uri","input":_vm.item.name ? _vm.item.name.ru : '',"label":"URL-адрес практики","placeholder":"Slug","error":errors[0],"persistent-hint":"","hide-details":"auto"},model:{value:(_vm.item.slug),callback:function ($$v) {_vm.$set(_vm.item, "slug", $$v)},expression:"item.slug"}})]}}],null,true)}),_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"cols":"5"}},[_c('h2',{staticClass:"mb-4"},[_vm._v(" Meta ")]),_c('item-meta',{staticClass:"mb-8",model:{value:(_vm.item.meta),callback:function ($$v) {_vm.$set(_vm.item, "meta", $$v)},expression:"item.meta"}}),_c('locale-field',{attrs:{"name":"metaDescription","component":"v-textarea","auto-grow":"","dense":"","outlined":"","hide-details":"","rows":2,"label":"Description"},model:{value:(_vm.item.metaDescription),callback:function ($$v) {_vm.$set(_vm.item, "metaDescription", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.metaDescription"}})],1),_c('v-col',[_c('clinic-sharing-preview',{staticClass:"cv__share",attrs:{"title":_vm.item.meta.title && _vm.item.meta.title.ru ? _vm.item.meta.title.ru : _vm.item.name.ru,"description":_vm.item.metaDescription.ru,"image":_vm.item.socialImage},on:{"input:image":function($event){_vm.item.socialImage = $event}}})],1)],1),_c('clinic-hero',{attrs:{"value":{
                  desktop: _vm.item.mainWidePhoto,
                  mobile: _vm.item.mainSquarePhoto,
                }},on:{"input":function (ref) {
                  var desktop = ref.desktop;
                  var mobile = ref.mobile;

                  _vm.item.mainSquarePhoto = mobile
                  _vm.item.mainWidePhoto = desktop
                }}}),_c('div',{staticClass:"mt-12 mb-12"},[_vm._v(" Фото для карточки практики "),_c('image-select',{staticStyle:{"max-width":"200px"},model:{value:(_vm.item.cardImage),callback:function ($$v) {_vm.$set(_vm.item, "cardImage", $$v)},expression:"item.cardImage"}}),_c('image-requirements',{staticClass:"caption",attrs:{"size":"534px","ratio":"1/0.81"}})],1),_c('div',{staticClass:"text-h5 mt-12 mb-4"},[_vm._v(" Блоки ")]),_c('practice-blocks',{model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),_c('v-tab-item',{key:"contacts"},[_c('clinic-contacts',{model:{value:(_vm.item.contacts),callback:function ($$v) {_vm.$set(_vm.item, "contacts", $$v)},expression:"item.contacts"}})],1),_c('v-tab-item',{key:"management",attrs:{"eager":""}},[_c('clinic-managers-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var pending = ref.pending;
return [(pending)?_c('div',[_vm._v(_vm._s(_vm.$t('notes.loading')))]):_c('clinic-managers-editor',{staticClass:"mb-12",model:{value:(_vm.item.generalManagers),callback:function ($$v) {_vm.$set(_vm.item, "generalManagers", $$v)},expression:"item.generalManagers"}})]}}],null,true),model:{value:(_vm.item.generalManagers),callback:function ($$v) {_vm.$set(_vm.item, "generalManagers", $$v)},expression:"item.generalManagers"}})],1),_c('v-tab-item',{key:"other"},[_c('div',[_c('v-text-field',{attrs:{"label":"Airtable Id","outlined":"","dense":""},model:{value:(_vm.item.airtableId),callback:function ($$v) {_vm.$set(_vm.item, "airtableId", $$v)},expression:"item.airtableId"}})],1)])],1)],1)],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }